
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import useMutationCommon from '@/hooks/useMutationCommon'
import useOpenInfo from '@/hooks/openInfo'
import stepPercentage from '@/utils/number'
import { setApptitle } from '@/utils/native-app'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import dayjs from 'dayjs'
import * as storage from '@/utils/storage'

export default defineComponent({
  setup () {
    const router = useRouter()
    const privilegeList = [
      {
        name: '国际配售',
        url: require('@/assets/images/international.svg')
      },
      {
        name: '私募基金',
        url: require('@/assets/images/fund.svg')
      },
      {
        name: '非零售债券',
        url: require('@/assets/images/non-retail.svg')
      }
    ]
    const isPiVerify = ref(1)
    const { openType, meta, title, step, saveCommonOfVuex, saveOpenInfoOfVuex } = useOpenInfo()
    const handleNext = () => {
      isPiVerify.value = 1
      const percentage = stepPercentage(step)
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify({
          isPiVerify: isPiVerify.value
        })
      }
      useSaveOpenInfo(params)
      useMutationCommon(common).then(() => {
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({ info: { isPiVerify: isPiVerify.value } })
        router.push({ name: 'pi-risk' })
      })
    }
    const skipPI = () => {
      isPiVerify.value = 0
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify({
          isPiVerify: isPiVerify.value
        })
      }
      useSaveOpenInfo(params)
      saveOpenInfoOfVuex({ info: { isPiVerify: isPiVerify.value } })
      const expireTime = dayjs(storage.get('captcha_expire_time'))
      const diff = dayjs().diff(expireTime, 'minute', true)
      if (diff < 10) {
        router.push({ name: 'confirm' })
      } else {
        router.push({ name: 'captcha' })
        storage.remove('captcha_expire_time')
      }
    }
    onMounted(() => {
      setApptitle(title)
    })

    return { handleNext, privilegeList, isPiVerify, skipPI }
  }
})
